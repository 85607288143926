import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios';
import { serialize } from 'object-to-formdata';
Vue.use(Vuex)

const CEBackgroundImages = [
  'https://hawaiilife-app-assets.s3.us-west-2.amazonaws.com/lead-team/aprl_resized.jpg',
  'https://hawaiilife-app-assets.s3-us-west-2.amazonaws.com/lead-team/Kahea_Zietz_300px-270x270.jpg',
]

const r = Math.floor(Math.random() * Math.floor(CEBackgroundImages.length));
const CEImage = CEBackgroundImages[r];

export default new Vuex.Store({
  state: {
    googleMapsHeadAdded:false,
    googleMapsLoaded:false,
    showGenericContactModal:false,
    showGenericContactPhoneNumber:true,
    genericContactModalText:'Contact Us',
    genericContactModalHeader:'We would be happy to help!',
    genericContactModalLeadType:'HL Web - Question',
    genericContactModalImage:null,
    genericContactModalButtonText:'Reach Out!!',
    genericContactModalSize:'wide',
    genericContactModalTheme:'hawaii-life',
    mlsIdDataMap:{},
    showCTAModal:false,
    activeCTATemplate:null,
    userAdvisor:null,
    genericContactModalDefaults:{
      theme:'hawaii-life',
      text:'Contact Us',
      headerText:"We would be Happy to Help!",
      leadType:'HL Web - Question',
      image:null,
      size:'wide',
    },
    activeCTATemplateName:null,
    ctaTemplates:{
      vacation_investment:{
        header:"Hawaii's Vacation Rental Property Statement of Facts & Trends.",
        subheader:"Get an island-by-island breakout of the latest market intelligence and forecasting for Hawaii's vacation rentals, including laws & regulations report.",
        html:``,
        img:'https://hawaiilife-app-assets.s3.us-west-2.amazonaws.com/cta-marketing-materials/vacations/rental-marketvr-full.jpeg',
        successHeader:"We're taking you to your download.",
        successSubheader:'',
        hiddenFields:['address','comments'],
        download:true,
        leadSource:'HL Web - Rental Investment',
        submitText:'Access the Report',
        downloadURL:'https://hawaiilife.s3.us-west-2.amazonaws.com/vacation-rental-white-papers/9-HL-VR-Whitepaper_2024_web.pdf',
        displayCallOrText:false,
        defaultNotes:'Accessed VR Whitepaper',
        order: {
          promoGraphicPosition: 'right',
          formPosition: 'left'
        }
      },
      vacation_2024_state_of_market_whitepaper:{
        header:"The State of the Hawai‘i Vacation Rental Market",
        subheader:"In this white paper, we will analyze the September 2023 YTD data from the Hawai‘i Tourism Authority and Hawai‘i Life proprietary rental data to compare it with the " +  "same period the previous year, 2022.",
        html:``,
        img:'https://hawaiilife-app-assets.s3.us-west-2.amazonaws.com/cta-marketing-materials/vacations/rental-marketvr-full.jpeg',
        successHeader:"We're taking you to the whitepaper.",
        successSubheader:'',
        hiddenFields:['address','comments'],
        download:true,
        leadSource:'HL Web - Rental Investment',
        submitText:'Access the Report',
        downloadURL:'https://hawaiilife.s3.us-west-2.amazonaws.com/vacation-rental-white-papers/9-HL-VR-Whitepaper_2024_web.pdf',
        displayCallOrText:false,
        defaultNotes:'Accessed VR Whitepaper',
        order: {
          promoGraphicPosition: 'right',
          formPosition: 'left'
        }
      },
      vacation_properties:{
        header:"Receive Hawaii rental properties for sale.",
        subheader:"A local expert from our team will connect with you to share more.",
        html:``,
        img:'https://hawaiilife.s3.us-west-2.amazonaws.com/ce/global-reach-local-style.jpg',
        successText:'Mahalo. Here is your report.',
        downloadText:"Click here to download",
        hiddenFields:['address','comments'],
        download:false,
        leadSource:'HL Web - Rental Investment',
        submitText:'Get in touch',
        displayCallOrText:true,
        successHeader:'Mahalo. We will be in touch shortly.',
        successSubheader:'A local expert from our team will connect with you to share more.',
        defaultNotes:'I\'d like to learn more about active and potential units for sale in these complexes',
        order: {
          promoGraphicPosition: 'right',
          formPosition: 'left'
        }
      },
    },
    listingQueries:[],
    listings:[],
    relatedListings:[],
    relatedBlogs:[],
    areaContentData:null,
    contactUsCTAImageUrlDefault: CEImage,
    contactUsCTAImageUrlOverride: null,
    contactUsCTARecipientOverride: null,
    contactUsCTARecipientDefault: "Hawaii Life",
    contactUsCTAAgentEmail:null,
    currentIsland:null,
    currentDistrict:null,
    currentTown:null,
    currentCTAComponent:null,
    currentCommunity:null,
    phoneRegex:/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/,
    emailRegex: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,24}))$/,
    featuredAdvisorsForListing:[],

  },
  mutations: {
    fillGenericContactModalFromData(state,payload){
      if(!payload) return;
      if(payload.title){
        state.genericContactModalHeader = payload.title;
      }else{
        state.genericContactModalHeader = state.genericContactModalDefaults.headerText;
      }
      if(payload.theme) {
        state.genericContactModalTheme = payload.theme;
      }else{
        state.genericContactModalTheme = state.genericContactModalDefaults.theme;
      }
      if(payload.text){
        state.genericContactModalText = payload.text;
      }else{
        state.genericContactModalText = state.genericContactModalDefaults.text;
      }
      if(payload.message) {
        state.genericContactModalText = payload.message;
      }else{
        state.genericContactModalText = state.genericContactModalDefaults.text;
      }
      if(payload.image){
        state.genericContactModalImage = payload.image;
      }else{
        state.genericContactModalImage = null;
      }
    },
    setActiveCTATemplateName(state,payload){
      console.log('activeCTATemplateName',payload)
        state.activeCTATemplateName = payload;
        state.activeCTATemplate = state.ctaTemplates[payload];
    },
    setCurrentCTAComponent(state,payload){
      state.currentCTAComponent = payload;
    },
    setShowGenericContactModal(state,payload = true){
      state.showGenericContactModal = payload
    },
    setListingData(state,payload){
      state.listings = payload;
    },
    setFeaturedAdvisorsForListing(state,payload){
      state.featuredAdvisorsForListing = payload;
    },
    setUserAdvisorForCEWidget(state,agent){
      state.userAdvisor = agent;
    },
    updateShowCTAModal(state,payload = true){
        if(payload == false){
        }
        state.showCTAModal = payload;
    },
    setAreaContentData(state,payload){
      state.contactUsCTAImageUrlOverride =  null;
      state.contactUsCTARecipientOverride = null;
      state.areaContentData = payload;
      // if(payload.featured_agents_image && payload.featured_agents_image.length){
      //   state.contactUsCTAImageUrlOverride =  payload.featured_agents_image;
      //   return;
      // }
      if(payload && payload.featured_agents && payload.featured_agents.length){
        state.contactUsCTAImageUrlOverride =  payload.featured_agents[0].tile_image_url;
      }
    },
    setContactUsEmailForCTA(state,payload){
      state.contactUsCTAAgentEmail = payload;
    },
    setRelatedListings(state,payload){
      state.relatedListings = payload;
    },
    clearContactUsCTARecipientOverride(state){
      state.contactUsCTARecipientOverride = null;
    },
    clearContactUsCTAImageUrlOverride(state){
      state.contactUsCTAImageUrlOverride = null;
    },
    setCurrentIsland(state,island){
      state.currentIsland = island
    },
    setCurrentDistrict(state,district){
      state.currentDistrict = district;
    },
    setCurrentTown(state,town){
      state.currentTown = town;
    },
    setCurrentCommunity(state,community){
      state.currentCommunity = community;
    },
    setGenericContactModalHidden(state){
      state.showGenericContactModal = false;
    },
    showGenericContactModal(state){
      state.showGenericContactModal = true;
    },
    mapsLoaded(state){
      if(console) console.log('google maps was loaded');
      state.googleMapsLoaded = true;
    }
  },
  getters:{
    activeCTATemplateName(state){
      return state.activeCTATemplateName
    },
    activeCTATemplate(state){
      if(state.activeCTATemplateName){
        return state.ctaTemplates[state.activeCTATemplateName];
      }
    },
    showGenericContactModal(state){
      return state.showGenericContactModal;
    },
    showGenericContactModalPhoneNumber(state){
      return state.showGenericContactPhoneNumber;
    },
    genericContactModalHeader(state){
      return state.genericContactModalHeader
    },
    genericContactModalText(state){
      return state.genericContactModalText
    },
    genericContactModalImage(state){
      return state.genericContactModalImage
    },
    genericContactModalButtonText(state){
      return state.genericContactModalButtonText
    },
    genericContactModalSize(state){
      return state.genericContactModalSize;
    },
    genericContactModalTheme(state){
      return state.genericContactModalTheme
    },
    emailRegex(state){
      return state.emailRegex
    },
    phoneRegex(state){
      return state.phoneRegex
    },
    genericContactModalMessage(state){
      return state.genericContactModalMessage
    },
    areaContentData(state){
      return state.areaContentData;
    },
    relatedListings(state){
      return state.relatedListings;
    },
    contactUsCTAImageURL(state){
      if(state.contactUsCTAImageUrlOverride) return state.contactUsCTAImageUrlOverride;
      if(state.featuredAdvisorsForListing && state.featuredAdvisorsForListing[0]){
        return state.featuredAdvisorsForListing[0].tile_image_url;
      }
      if(state.userAdvisor){
        return state.userAdvisor.tile_image_url;
      }
      return state.contactUsCTAImageUrlDefault;
    },
    contactUsCTARecipient(state){
      if(state.contactUsCTARecipientOverride) return state.contactUsCTARecipientOverride;
      return state.contactUsCTARecipientDefault;
    },
  },
  actions: {
    async loadMLSById({state},id){
      if(state.mlsIdDataMap[id]){
        return state.mlsIdDataMap[id];
      }
      const {data} = await axios.get('https://www.hawaiilife.com/api/v1/listings?mls_id=' + id);
      if(data && data[0]){
        state.mlsIdDataMap[id] = data[0];
        return state.mlsIdDataMap[id];
      }
      return null;
    },
    async loadMLSByIds({state},ids){
      if(Array.isArray(ids)) ids = ids.join(',');
      const {data} = await axios.get('https://www.hawaiilife.com/api/v1/listings?mls_ids=' + ids);
      return data;
    },
    async loadQuery({commit},query){
    const {data} = await axios.get(query);
    return data;
    },
    async loadListingsFromWindowLocation(location){
      console.log('loadListingsFromWindowLocation',location);
    },
    async sendLeadFromData(data){
      var bodyFormData = new FormData();
      var location = window.location.href.substring(0,128);
      bodyFormData.append('contact[Email]',data.email);
      bodyFormData.append('contact[FirstName]',data.firstName ? data.firstName : 'Unknown');
      bodyFormData.append('contact[LastName]',data.lastName ? data.lastName : 'Unknown');
      bodyFormData.append('request[Inquiry_Message__c]',data.notes);
      bodyFormData.append('request[web_source__c]',location);
      if(data.recipient){
        bodyFormData.append('request[Listing_Agent_Email__c]',data.recipient);
      }
      bodyFormData.append('request[LeadSource__c]',data.leadSource ? data.leadSource : 'HL Web - Email Message');
      const {result} = await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a',bodyFormData,{
        headers: {
          'content-type': 'application/x-www-form-urlencoded'
        }
      });
      return data.data;
    }
  },
  modules: {
  }
})
