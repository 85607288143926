<template>
    <div class="ce-wrapper" :class="{'wider':showContactForm}" v-if="!hidden">
        <div class="ce-component">
            <div class="inner">
                <div class="success" v-if="showSuccess" @click="hideSuccessAndActionItems()">
                    <div class="inner-success">
                        <h2>Mahalo!</h2>
                        <p v-if="!customSuccessMessage">One of our experts will contact you shortly.</p>
                        <p v-if="customSuccessMessage">{{customSuccessMessage}}</p>
                    </div>
                </div>
                <div class="success" v-if="showChatLoading">
                    <div class="inner-success">
                        <h2>We'll be right with you.</h2>
                        <p v-if="!customSuccessMessage">Please wait just a second...</p>
                    </div>
                </div>

                <div class="contact-form-inner shadowed" v-if="showContactForm">
                    <div class="cf-header">
                        <h2 v-if="showCallBody">Give {{usOrMe}} a Call</h2>
                        <h2 v-if="showEmailBody">Send {{usOrMe}} an Email</h2>
                        <a class="close-x shadowed" @click="resetActionButtons"><svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve">
<polygon fill="white" points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
</svg></a>
                    </div>
                    <div class="cf-body">
                        <div v-if="showCallBody">
                            <a href="tel:800-370-3848" v-if="!scheduleCall" class="hl-button centered">Call Now!</a>
                            <h2 class="center-text" v-if="!scheduleCall" style="color:black;">or</h2>
                            <a style="width:100%; text-align: center; margin:0 auto; color:black;" class="schedule-call hover-pointer" v-if="!scheduleCall" @click.prevent="scheduleCall = true;"><span style="display:inline-block;"><span class="underline-blue dashed">Schedule</span> a call from us</span>
                                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                     viewBox="0 0 96 96" style="enable-background:new 0 0 96 96; width:25px;" xml:space="preserve">
                                    <g id="XMLID_2_">
	<path id="XMLID_19_" class="st0" d="M24.7,73.4h9.5v-9.5h-9.5V73.4z M36.4,73.4h10.6v-9.5H36.4V73.4z M24.7,61.8h9.5V51.2h-9.5
		V61.8z M36.4,61.8h10.6V51.2H36.4V61.8z M24.7,49.1h9.5v-9.5h-9.5V49.1z M49.1,73.4h10.6v-9.5H49.1V73.4z M36.4,49.1h10.6v-9.5
		H36.4V49.1z M61.8,73.4h9.5v-9.5h-9.5V73.4z M49.1,61.8h10.6V51.2H49.1V61.8z M37.4,33.2v-9.5c0-0.3-0.1-0.5-0.3-0.7
		c-0.2-0.2-0.5-0.3-0.7-0.3h-2.1c-0.3,0-0.5,0.1-0.7,0.3c-0.2,0.2-0.3,0.5-0.3,0.7v9.5c0,0.3,0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3h2.1
		c0.3,0,0.5-0.1,0.7-0.3C37.3,33.7,37.4,33.5,37.4,33.2z M61.8,61.8h9.5V51.2h-9.5V61.8z M49.1,49.1h10.6v-9.5H49.1V49.1z
		 M61.8,49.1h9.5v-9.5h-9.5V49.1z M62.8,33.2v-9.5c0-0.3-0.1-0.5-0.3-0.7c-0.2-0.2-0.5-0.3-0.7-0.3h-2.1c-0.3,0-0.5,0.1-0.7,0.3
		c-0.2,0.2-0.3,0.5-0.3,0.7v9.5c0,0.3,0.1,0.5,0.3,0.7s0.5,0.3,0.7,0.3h2.1c0.3,0,0.5-0.1,0.7-0.3S62.8,33.5,62.8,33.2z M75.5,31.1
		v42.3c0,1.1-0.4,2.1-1.3,3s-1.8,1.3-3,1.3H24.7c-1.1,0-2.1-0.4-3-1.3c-0.8-0.8-1.3-1.8-1.3-3V31.1c0-1.1,0.4-2.1,1.3-3
		c0.8-0.8,1.8-1.3,3-1.3H29v-3.2c0-1.5,0.5-2.7,1.6-3.7c1-1,2.3-1.6,3.7-1.6h2.1c1.5,0,2.7,0.5,3.7,1.6c1,1,1.6,2.3,1.6,3.7v3.2
		h12.7v-3.2c0-1.5,0.5-2.7,1.6-3.7c1-1,2.3-1.6,3.7-1.6h2.1c1.5,0,2.7,0.5,3.7,1.6c1,1,1.6,2.3,1.6,3.7v3.2h4.2c1.1,0,2.1,0.4,3,1.3
		C75.1,28.9,75.5,29.9,75.5,31.1z"/>
</g>
</svg>
                            </a>
                            <div v-if="scheduleCall">
                                <h2 class="black-text">Schedule a callback</h2>
                                <div style="margin-bottom:20px;">
                                    <input type="text" class="hl-input" :class="{error:phoneNumberError && submitted}" v-model="phoneNumber" placeholder="Enter Phone Number" />
                                </div>
                                <div style="height:60px;">
                                    <div style="display:none">{{callDate}}</div>
                                    <calendar v-model="callDate" :minDate="today"  format="ddd, MMM D" color="#12b5ea"></calendar>
                                </div>
                                <select class="hl-select" :class="{error:timeError && submitted}" v-model="callTime">
                                    <option :value="null" disabled>When</option>
                                    <option v-for="(time,idx) in times" v-if="showTime(idx)" :value="idx">{{time}}</option>
                                </select>
                                <a @click="scheduleCallback" class="hl-button centered">Schedule Call</a>
                            </div>
                        </div><!--end of show call body -->
                        <div v-if="showEmailBody">
                            <div class="hl-form-group-horizontal">
                                <label class="hl-label">To</label>
                                <div class="label"> <span style="color:black;">{{emailTo}}</span></div>
                            </div>
                            <div class="hl-form-group-horizontal">
                                <label class="hl-label">From</label>
                                <input type="text" v-model="fromEmail" @keyup="validateSendEmail" :class="{error:!emailValid && emailSubmitted}" class="hl-input mb-10" placeholder="Enter Your Email" />
                            </div>
                            <textarea class="hl-input" placeholder="Please Enter a Message" rows="4" cols="50" style="height:auto; min-height:100px; resize: none;" v-model="inquiryMessage"></textarea>
                            <a @click="sendEmail" class="hl-button centered" :class="{disabled:!emailValid}">Send Email</a>
                        </div>
                    </div><!--end of show call body -->
                </div>
                <div class="clearfix"></div>
                <div class="action-items-wrapper" @click="logInteraction" :class="{hidden:!showActionItems, shown:showActionItems}">
                    <div class="action-item grey" v-if="showActionItems" @click="showActionItems = false;">
                        <svg version="1.1" id="Layer_2" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" style="width:50%; height:50%;"
                             viewBox="0 0 96 96" enable-background="new 0 0 96 96" xml:space="preserve">
<polygon points="96,14 82,0 48,34 14,0 0,14 34,48 0,82 14,96 48,62 82,96 96,82 62,48 "/>
</svg>
                    </div>

                    <div class="action-item phone" @click="showForm('call')" @mouseover="callBackHideCancelled = true" v-if="showActionItems">
                        <div class="label">
                            Call {{usOrMe}}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><defs></defs><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M8.84889,19.04222A37.02993,37.02993,0,0,0,24.95778,35.15111l5.37778-5.37777a2.43042,2.43042,0,0,1,2.49333-.58667A27.88393,27.88393,0,0,0,41.55556,30.58,2.45163,2.45163,0,0,1,44,33.02445v8.53111A2.45163,2.45163,0,0,1,41.55556,44,41.55193,41.55193,0,0,1,0,2.44444,2.45163,2.45163,0,0,1,2.44444,0H11a2.45163,2.45163,0,0,1,2.44444,2.44444,27.76963,27.76963,0,0,0,1.39334,8.72667,2.45312,2.45312,0,0,1-.61111,2.49333Z"/></g></g></svg>
                    </div>
                    <div class="action-item sms" @click="() => { startLiveChat(); }" @mouseover="callBackHideCancelled = true" v-if="showActionItems && showLiveChat">
                        <div class="label">
                            Live Chat
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 44"><defs></defs>><g id="Layer_2" data-name="Layer 2"><g id="Layer_1-2" data-name="Layer 1"><path class="cls-1" d="M41.8,8.8H37.4V28.6H8.8V33A2.20647,2.20647,0,0,0,11,35.2H35.2L44,44V11A2.20647,2.20647,0,0,0,41.8,8.8ZM33,22V2.2A2.20647,2.20647,0,0,0,30.8,0H2.2A2.20647,2.20647,0,0,0,0,2.2V33l8.8-8.8h22A2.20647,2.20647,0,0,0,33,22Z"/></g></g></svg>
                    </div>

                    <a class="action-item sms" href="sms:+18003703848&body=Hi, I have a question about Hawaii real estate" @mouseover="callBackHideCancelled = true" v-if="showActionItems">
                        <div class="label">
                            Text {{usOrMe}}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 0 24 24" width="24"><path d="M20 2H4c-1.1 0-1.99.9-1.99 2L2 22l4-4h14c1.1 0 2-.9 2-2V4c0-1.1-.9-2-2-2zM9 11H7V9h2v2zm4 0h-2V9h2v2zm4 0h-2V9h2v2z"/></svg>
                         </a>
                    <div class="action-item email" @click="showForm('email')" @mouseover="callBackHideCancelled = true" v-if="showActionItems">
                        <div class="label">
                            Email {{usOrMe}}
                        </div>
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 44 27.71178"><defs></defs><g id="Layer_3"><g id="Layer_1-2" data-name="Layer 1"><polygon class="cls-1" points="0 0.77 0 26.967 16.033 14.402 0 0.77"/><path class="cls-1" d="M22.38051,19.15306a.58785.58785,0,0,1-.76058,0l-4.675-3.97519L.95114,27.71178H43.0493L27.05517,15.17816Z"/><polygon class="cls-1" points="43.094 0 0.906 0 22 17.936 43.094 0"/><polygon class="cls-1" points="44 26.966 44 0.77 27.968 14.402 44 26.966"/></g></g></svg>
                    </div>
                </div>
                <div class="contact-live-chat-wrapper" v-if="!showActionItems && !showContactForm" @click="showAndCancelHideCallbackOverride()">
                    <div class="start-chat-message" v-bind:style="chatBubbleStyle" ref="chat-text" :class="{hidden: showActionItems || showContactForm}">
                        <span id="ce-chat-text" >{{startChatBubbleMessage}}</span>
                    </div>
                    <div class="contact-live-chat" :style="getLiveChatImageStyles" ref="contact-live-chat" @click="showActionItems = !showActionItems">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    const {DateTime} = require('luxon');
    import { VueDatePicker } from '@mathieustan/vue-datepicker';
    import axios from 'axios';
    import {mapGetters} from 'vuex';
    const apiBase = window.location && window.location.href.includes('localhost') ? 'http://localhost:3000/api/lead/create' : 'https://platform.hawaiilife.com/api/lead/create';
    export default {
        name: "CEComponent",
        props:['agentSiteEmbed','agentImageUrl','leadingMessage','agentEmail'],
        components:{
            Calendar:VueDatePicker,
        },
        mounted(){
            this.attachEvents();
            setTimeout(() => {
              try{
              const result = this.loadMLSIDIfOnListing();
              if(result) this.mlsNumber = result;
              }catch(e){
                //do nothing because we cant find the MLS id
              }
            },3000);
        },
        methods:{
            triggerActionItems(){
                if(!this.actionItemsClickedOpen && this.windowWidth < 600){
                    this.actionItemsClickedOpen = true;
                    return;

                }else{
                    this.actionItemsClickedOpen = false;
                }
                this.startLiveChat();
            },
            loadMLSIDIfOnListing(){
              const result = document.querySelector('#mls_id strong');
              if(result && result.textContent) return result.textContent;
            },
            startLiveChat(){
                const {embedded_svc} = window;
                this.showChatLoading = true;
                try {
                    embedded_svc.inviteAPI.inviteButton.acceptInvite();
                    this.showActionItems = false;
                }catch(e){
                    //do nothing
                }
            },
            logInteraction() {
              if(window && window.HLIntelligence){
                window.HLIntelligence.track('ce_widget','interaction');
              }

              },
          attachEvents(){
                window.HL_ON_LIVE_AGENT_CLOSE = ( ) => {
                    this.resetActionButtons();
                };
                window.HL_ON_LIVE_AGENT_OPEN = () => {
                    this.showActionItems = false;
                    this.showChatLoading = false;
                }
                window.addEventListener('resize',this.measureElements);
                window.HL_ON_SCHEDULE_CALL_OPEN = () => {
                  this.showCallBody = true;
                  this.showActionItems = false;
                  this.showContactForm = true;
                  this.scheduleCall = true;
                };
                window.HL_TRIGGER_LIVE_CHAT_WITH_MESSAGE = (message = null, mls_id = null,additional_information = null) => {
                  this.inquiryMessage =  message;
                  this.showContactForm = true;
                  this.showEmailBody = true;
                  this.showActionItems = false;
                  this.forcedCTA = true;

                  if(mls_id && mls_id.length){
                    this.locationOverride = 'https://www.hawaiilife.com/mls/' + mls_id.toString();
                  }
                  if(additional_information){
                    this.additional_information = additional_information;
                  }

                }
                setTimeout(this.measureElements,300);
                if(!this.showHideFired){
                    return;
                    setTimeout(() => {
                        this.showActionItems = true;
                        setTimeout(() => {
                            if(this.callBackHideCancelled) return;
                            this.showActionItems = false;
                        },5000);
                    },2000);
                }
            },
            hideSuccessAndActionItems(){
                this.showSuccess = false;
                this.showActionItems = false;
            },
            measureElements(){
                const w = window.outerWidth;
                this.windowWidth = w;
                if(!this.$refs) return;
                try {
                    const circle = this.$refs['contact-live-chat'];
                    const circleRect = circle.getBoundingClientRect();
                    const chatText = this.$refs['chat-text'];
                    this.circleRectangle = circleRect;
                    this.chatTextRectangle = chatText.getBoundingClientRect();
                }catch(e){

                }
                //this.contactLiveChatWrapper = t
            },
            showForm(type = 'call'){
                this.showContactForm = true;
                if(type == 'call'){
                    this.showCallBody = true;
                }
                if(type == "email"){
                    this.showEmailBody = true;
                }
                this.showActionItems = false;
            },
            async sendEmail(){
              this.emailSubmitted = true;
              if(!this.emailValid){
                return;
              }
                const data = {
                    name:null,
                    email:this.fromEmail,
                    phone:this.phoneNumber,
                    contactReason:'Other',
                    notes:this.inquiryMessage + `\n\n Sent from: CE Widget`,
                }
                this.sendInquiryNew(data);
                this.customSuccessMessage = 'Thank you for reaching out. We will be in contact with your shortly';
                this.showSuccess = true;
                this.showEmailBody = false;
                this.showContactForm = false;
            },
            async sendInquiry(sendData) {
              const {data} = await axios.post(apiBase, sendData);
            },

            async sendInquiryNew(sendData){
                var bodyFormData = new FormData();
                var location = window.location.href.substring(0,128);
                if(this.locationOverride){
                  location = this.locationOverride;
                }

                if(this.additional_information){
                  sendData.notes += "\n" + this.additional_information + `\n - CE Widget `;
                }
                if(this.agent || this.agentEmailForLeads){
                  bodyFormData.append('request[Listing_Agent_Email__c]',this.agentEmailForLeads);
                }

                bodyFormData.append('contact[Email]',sendData.email);
                bodyFormData.append('contact[FirstName]',sendData.firstName ? sendData.firstName : 'Unknown');
                bodyFormData.append('contact[LastName]',sendData.lastName ? sendData.lastName : 'Unknown');
                bodyFormData.append('request[Inquiry_Message__c]',sendData.notes);
                bodyFormData.append('contact[Leadsource]', 'HL Web - Email Message');
                bodyFormData.append('request[web_source__c]',location);
                bodyFormData.append('request[LeadSource__c]','HL Web - Email Message');
                if(this.mlsNumber) bodyFormData.append('request[MLS_Number__c]',this.mlsNumber);
                const {data} = await axios.post('https://front-desk.propertybase.com/forms/2040d147d0f00f83022db26be3abbe33c9ad460a',bodyFormData,{
                  headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                  }
                });
                return data;
            },
            showAndCancelHideCallbackOverride(){
                this.showActionItems = true;
                this.callBackHideCancelled = true;
            },
            async scheduleCallback(){
                const date = new Date();
                const now = DateTime.fromJSDate(new Date(),{zone:'local'});
                const d = DateTime.fromJSDate(new Date(),{zone:'Pacific/Honolulu'});
                this.submitted = true;
                const result = this.validateCallbackForm();
                if(!result) return;
                const hours = now.hour - d.hour;
                const minutes = now.minute - d.minute;
                let dateToCall = this.callDate;
                if(typeof dateToCall === 'string'){
                    dateToCall = DateTime.fromFormat(this.callDate,'y-MM-dd').toJSDate();
                    dateToCall.setHours(0,0);
                }
                const time = DateTime.fromJSDate(dateToCall);
                const time2 = time.set({hours:0,minutes:0});
                const hour = Math.floor(this.callTime / 60);
                const minute = this.callTime % 60;
                const theirtime = time2.plus({hour,minute});
                const change = time2.plus({hours: hour - hours,minutes: minute - minutes});
                this.showSuccess = true;
                this.customSuccessMessage = `We will call you at ${theirtime.toFormat('hh:mm a')} on ${theirtime.toFormat('M/d')}`;
                this.customSuccessMessage += ` - Hawaii Time: ${change.toFormat('M/d h:mm a')}`;
                let message = `Call them at ${theirtime.toFormat('hh:mm a')} on ${theirtime.toFormat('M/d')}- Hawaii Time: ${change.toFormat('M/d h:mm a')}`;
                if(this.additional_information){
                  message += "\n" + this.additional_information + `\n CE - widget` ;
                }

                const inquiryData =  {
                    email:null,
                    phone:this.phoneNumber,
                    notes:message,
                    contactReason:'Other',
                    requestCallBack:1,
                    callTimeHST:change,
                    theirTime:theirtime,
                    callback:true,
                };
                this.submitted = false;
                await this.sendInquiry(inquiryData);
                this.resetActionButtons();
                this.resetData();
            },
            resetData(){
                this.phoneNumber = null;
                this.callDate = new DateTime();
                this.message = null;
                this.emailSubmitted = false;
                this.emailValid = false;
                this.locationOverride = null;
                this.additional_information = null;
            },
            resetActionButtons(){
                this.showContactForm = false;
                this.showActionItems = true;
                this.showEmailBody = false;
                this.showCallBody = false;
                this.scheduleCall = false;
                if(this.forcedCTA){
                  this.hideSuccessAndActionItems();
                  this.forcedCTA = false;
                }
            },
            showTime(minutes){
                const now =  DateTime.fromJSDate(new Date());
                const selected = DateTime.fromJSDate(this.callDate);
                if(selected.diffNow().days === 0){
                    const minutesNow = (now.hour * 60) + now.minute;
                    if(minutesNow + 30 > minutes){
                        return false;
                    }
                }
                //todo, compare

                return true;
            },
            validateSendEmail(){
              const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
              this.emailValid = re.test(this.fromEmail.toLowerCase());
              return this.emailValid;
              },
            validateCallbackForm(){
                if(this.timeError) return false;
                if(this.phoneNumberError) return false;
                return true;
            }
        },
        data(){
            let now = new Date();
            return {
                inquiryData:{
                    name:null,
                    email:null,
                    phone:null,
                    contactReason: null,
                    notes:''
                },
                emailValid:false,
                windowWidth:null,
                callDate:now,
                today:new Date(),
                emailSubmitted:false,
                showActionItems:false,
                showContactForm:false,
                forcedCTA:false,
                showCallBody:false,
                showEmailBody:false,
                chatWelcomeText:`Aloha!`,
                phoneNumber:null,
                callTime:null,
                tomorrow:now,
                fromEmail:null,
                scheduleCall:false,
                showSuccess:false,
                customSuccessMessage:null,
                additional_information:null,
                submitted:false,
                showChatLoading:false,
                showActionItemsOverride:false,
                actionItemsClickedOpen:false,
                showHideFired:false,
                inquiryMessage:null,
                chatTextWidth:null,
                callBackHideCancelled:false,
                chatTextRectangle:null,
                circleRectangle:null,
                messageOverride:null,
                locationOverride:null,
                mlsNumber:null,
                times:{
                    480:'8am',
                    510:'8:30am',
                    540:'9am',
                    570:'9:30am',
                    600:'10am',
                    630:'10:30am',
                    660:'11am',
                    690:'11:30am',
                    720:'12pm',
                    750:'12:30pm',
                    780:'1pm',
                    810:'1:30pm',
                    840:'2pm',
                    870:'2:30pm',
                    900:'3pm',
                    930:'3:30pm',
                    960:'4pm',
                    990:'4:30pm',
                    1020:'5pm',
                    1050:'5:30pm',
                    1080:'6pm',
                    1110:'6:30pm',
                    1140:'7pm',
                    1170:'7:30pm',
                    1200:'8pm',
                    1230:'8:30pm',
                    1260:'9pm',
                    1290:'9:30pm',
                    1320:'10pm',
                }
            }
        },
        computed:{
            ...mapGetters(['contactUsCTAImageURL','contactUsCTARecipient']),
            getLiveChatImageStyles(){

              return {
                    'background-image': `url(${this.contactUsCTAImageURL})`
            }
            },
            showLiveChat(){
              if(this.agent && this.agent.email !== 'customerexperience@hawaiilife.com') return false;
              return true;
            },
            agent(){
              return this.$store.state.userAdvisor;
            },
            agentFirstName(){
              if(this.agent){
                return this.agent.user_name.split(" ")[0];
              }
              return null;
            },
            agentEmailForLeads(){
              if(this.agent){
                return this.agent.email;
              }
              if(this.agentEmail){
                return this.agentEmail;
              }
            },
            agentLastName(){
              if(this.agent){
                return this.agent.user_name.split(" ")[1];
              }
              return null;
            },
            emailTo(){
              if(this.$store.state.userAdvisor) return this.$store.state.userAdvisor.user_name;
              return 'Hawaii Life';
            },
            usOrMe(){
              if(this.$store.state.userAdvisor && this.agentFirstName !== "Customer"){
                return this.agentFirstName
              }
              return 'Us';
            },
            hidden(){
                if(window.HL_CE_ON_BLOG){
                    if(window.location.href.includes('/blog')) return true;
              }
              return false;
            },
            phoneNumberError(){
                if(this.phoneNumber) {
                    const match = this.phoneNumber.match(/^(\+\d{1,2}\s?)?1?\-?\.?\s?\(?\d{3}\)?[\s.-]?\d{3}[\s.-]?\d{4}$/);
                    if(!match) return true;
                }
                if(!this.phoneNumber) return true;
                return false;
            },
            error(){
                return this.phoneNumberError || this.timeError;
            },
            timeError(){
                if(!this.callTime  || !this.phoneNumber) return true;
                if(!this.callTime && this.phoneNumber) return true;
                return false;
            },
            hiddenActionItems(){
                return this.windowWidth <= 600 && !this.actionItemsClickedOpen;
            },
            narrowWidth(){
                return this.windowWidth <= 600;
            },
            chatBubbleStyle(){
                if(!this.circleRectangle) return {};
                return {
                   'margin-left':(-this.chatTextRectangle.width - 10 ) + 'px',
                }
            },
            startChatBubbleMessage(){
               return this.chatWelcomeText;
            },
        }
    }
</script>

<style lang="scss">
    body.embedded-chat{
        .datepicker{
            font-family: "Whitney A", "Whitney B", sans-serif;
        }
        span.datepicker-header__year-button{
            display:none !important;
        }
        .ce-wrapper{
            @media(max-width: 500px){
                right:-55px;
            }
            &.wider{
                width:320px;
            }
            width:200px;
            position:fixed;
            bottom:10px;
            right:-20px;
            z-index:5000;
            &.inner{
                position:relative;
                height:100%;
                width:100%;
                margin:5px;
            }
        }

        .ce-component{
            .action-items-wrapper{
                width:100%;
                *{
                    display:block;
                    margin:0 auto;
                }
            }
            font-family: "Whitney A", "Whitney B", sans-serif;
            .inner{
                height:100%;
                display:flex;
                flex-direction: column;
                align-items: center;
                justify-content: flex-end;
                padding-top:100px;
                width:100%;
                @media (max-width:500px){
                    padding-top:0px;
                }
            }
            div.contact-live-chat{
                transition: all .2s ease-in-out;
                height:10vw;
                width:10vw;
                &:hover{
                  cursor:pointer;
                  transform: scale(1.1);
                }
                border: 5px #12B5EA solid;
                border-radius: 20vh;
                background-size: cover;
                background-position: center center;
                max-height:100px;
                max-width:100px;
                @media (max-width:600px){
                    height:13vw;
                    width:13vw;
                    min-width:70px;
                    min-height: 70px;
                    border-radius: 14vw;
                    border-width: 1px;
                }
            }

            div.action-items-wrapper{
                left:auto;
                right:10px;
                opacity:0.3;
                transition: opacity 2.5s ease;
                &.hidden{
                    opacity: 0;
                    transition: opacity 2.5s ease;
                }
                &.shown{
                    opacity: 1;
                    transition: opacity 2.5s ease;
                }
            }

            .action-item{
                margin-bottom: calc(10px + 1vh);
                height:2.5vw;
                width:2.5vw;
                min-width:70px;
                min-height:70px;
                background-color: #12b5ea;
                border-radius: 20vw;
                opacity:1;
                background-position: center center;
                background-size:cover;
                box-shadow: -2px -2px 32px 0px rgba(80,80,80,0.83);
                position:relative;
                display:flex;
                align-items: center;
                svg{
                    height:27px;
                    width:27px;
                    path,polygon{
                        fill:white;
                        &.cls-1{
                            fill:white !important;
                        }
                    }
                }
                .label{
                    display:none;

                }
                &.grey{
                    background-color: rgba(200,200,200,0.8);
                    opacity:0.8;
                    width:calc(3vw - 60px);
                    height:calc(3vw - 60px);
                    min-height:40px;
                    min-width:40px;
                    polygon{
                        fill:#333;
                    }
                }
                &:hover {
                    .label {
                        display:block;
                        width: auto;
                        margin-left: -130px;
                        min-width:100px;
                        text-align: center;
                        position: absolute;
                        padding: 5px;
                        background-color: #665726;
                        border-radius: 5px;
                        text-transform: uppercase;
                        font-weight: bold;
                        color: white;
                        font-size: 10px;
                        @media(max-width: 500px) {
                            display:none;
                        }
                    }

                }
                @media(max-width:550px){
                    min-width: 70px;
                    min-height:70px;
                    width:14vw;
                    height:14vw;
                }
                &:hover{
                    transform: scale(1.1);
                    cursor:pointer;
                }
                @media screen and (orientation: landscape) {
                    height:2vh;
                    width:2vh;
                    min-height:50px;
                    min-width:50px;
                }
            }

            div.contact-live-chat-wrapper{
                display:flex;
                flex-direction: row;
            }


            div.start-chat-message{
                font-family: "Whitney A", "Whitney B", sans-serif;
                text-align: center;
                position:fixed;
                width:auto;
                &.hidden{
                    visibility: hidden;
                }
                span{
                    box-sizing: border-box;
                    font-size:20px;
                    display:block;
                    background-color: rgba(18, 181, 234, 0.8);
                    color:white;
                    font-weight:700;
                    height:auto;
                    min-height:30px;
                    border-radius: 30px;
                    border-bottom-right-radius: 0px;
                    padding:10px 10px;
                    margin-left:-5vh;
                    @media (max-width:700px){
                        padding:8px;
                        margin-left:-15px;
                        margin-top:-10px;
                        font-size:14px;
                    }
                }
            }

            div.contact-form-inner{
                h2.black{
                    color:black;
                }
                label.hl-label{
                    color:black;
                    font-size:14px;
                }
                input[type="text"].hl-input{
                    padding:1px 35px 1px 10px;
                    line-height:17px;
                    &:focus{
                        margin-bottom:10px;
                    }
                }
                textarea{
                  line-height:17px;
                }
                position:fixed;
                bottom:40px;
                right:25px;
                font-family: "Whitney A", "Whitney B", sans-serif;
                max-width:320px;
                min-height:300px;
                background-color: rgba(0,0,0,0.0);
                border-radius:20px;
                @media(max-width:500px){
                    position:fixed;
                    width:90vw;
                    padding-left:5vw;
                    bottom:10px;
                    top:auto;
                }
                .cf-header{
                    box-sizing: content-box;
                    padding:5px 0;
                    background-image: linear-gradient(to right, rgb(25,138,196),rgb(36,170,223), rgb(156,210,248));
                    border-radius:5px;
                    border-bottom-right-radius: 0;
                    border-bottom-left-radius: 0;
                    font-size:17px;
                    @media(max-width: 500px){
                        border-radius: 10px;
                        border-bottom-right-radius: 0;
                        border-bottom-left-radius: 0;
                    }
                    min-width:250px;
                    text-align: left;
                    color:white;
                    position:relative;
                    h2{
                        margin-block-start: 0;
                        margin-block-end: 0;
                        margin:5px 0px;
                        text-align: center;
                    }
                    .close{
                        position:absolute;
                        top:5px;
                        right:5px;
                        &:hover{
                            cursor:pointer;
                        }
                    }
                }
                .cf-body{
                    position:relative;
                    padding:20px;
                    background-color: white !important;
                    min-height:10px;
                    box-sizing: border-box;
                    border-bottom-right-radius: 5px;
                    border-bottom-left-radius: 5px;
                    @media(max-width: 500px){
                        border-bottom-right-radius: 10px;
                        border-bottom-left-radius: 10px;
                        .hl-button{
                            width:90%;
                        }
                    }
                    padding-bottom:40px;
                    -webkit-box-shadow: 5px 5px 17px 5px rgba(0,0,0,0.27);
                    box-shadow: 5px 5px 17px 5px rgba(0,0,0,0.27);
                    .hl-button{
                        margin-top:20px;
                    }
                    .datepicker__wrapper{
                        .datepicker__input{
                            border:1px #f3f3f3 solid;
                            padding:5px;
                            border-radius:5px;
                            color:#222222 !important;
                        }
                    }
                }

                a.close-x{
                    svg{
                        width:20px;
                        height:16px;
                        position:absolute;
                        right:15px;
                        top:15px;
                    }
                    &:hover{
                        cursor:pointer;
                    }
                }

            }

            select{
                min-width:50%;
                @media(max-width: 500px) {
                    font-size: 16px;
                }
                font-weight:400;
                option {
                    size:14px;
                }
                &.error{
                    outline:1px red solid;
                }
            }

            div.success{
                position:fixed;
                top:0;
                left:0;
                height:100vh;
                width:100vw;
                display:flex;
                background: radial-gradient(rgba(255,255,255,0.9),rgba(255,255,255,0.8),rgba(255,255,255,0.6),rgba(255,255,255,0.0));
                display:flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                font-size:32px;
                color:black;
                transition: visibility 0s, opacity 0.5s linear;
                h2{
                    font-size:32px;
                }
                p{
                    font-size:20px;
                }
                .inner-success{
                    width:60%;
                    margin:0 auto;
                    box-sizing: border-box;
                }
            }



        }

        button.submitButton.uiButton--default.uiButton.embeddedServiceSidebarButton{
            border-radius: 0;
            font-family: "Whitney B","Whitney A";
            font-weight:700;
            font-size:18px;
            &:hover{
                background-color: #665726;
            }
        }

    &.embedded-chat-enabled .embeddedServiceSidebar{
        @media only screen {
            padding-top: 50px;
            padding-bottom: 50px;
        }
    }
        .layout-docked .dockableContainer{
            @media(min-width:500px){
            margin-bottom:20px;
            }
        }


      &.embedded-chat-enabled .embeddedServiceSidebar.layout-docked {

            .dockableContainer {
                border-radius: 5px;
                span#headerTextLabel {
                    color: rgb(255, 255, 255);
                    font-family: "Whitney A", "Whitney B", sans-serif;
                    font-size: 24px;
                    font-weight: bold;
                    margin-top: -5px;
                }

                .shortHeader {
                    background-image: linear-gradient(to right, rgb(25, 138, 196), rgb(36, 170, 223), rgb(156, 210, 248));
                    border-top-left-radius: 5px;
                    border-top-right-radius: 5px;
                }

                .startButton.uiButton--default.uiButton {
                    text-transform: uppercase;
                    font-family: "Whitney A", "Whitney B", sans-serif;
                    font-weight: 700;
                    text-decoration: none;
                }

            }
        }
        .menu__content.menu__content--active.menu__content--fixed{
            z-index:10000 !important;
        }
        .st0{fill:#ffffff;}

        .hl-button.smaller-text span{
            font-size:12px;
            padding-top:10px;
        }
        a.schedule-call{
            color: rgb(34, 34, 34);
            display:block;
            margin:0 auto;
            font-size:16px !important;
            position:relative;
            line-height: 35px;
            svg{
                margin-bottom:-7px;
                display:inline-block;
                path{
                    fill:#333;
                }
            }
        }
    }
</style>
