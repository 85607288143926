<template>
    <div class="hl-web-component">
        <div class="contact-form">
            <div class="overlay-cover" v-if="sending ">
                <h2>Sending...</h2>
            </div>
        <span class="square-label">{{title ? title : 'Contact Us'}}</span>
        <div class="form-group">
            <label for="">Full Name <span class="error" v-if="hasError('name')">You must enter your name</span></label>
            <input type="text" class="hl-input" v-model="data.name" :class="getErrorForField('name')" placeholder="Please enter your name" />
        </div>
        <div class="form-group">
            <label for="">Email Address <span class="error" v-if="hasError('name')">You must enter an email address</span></label>
            <div style="position:relative;" class="input-wrapper-with-icon">
            <input type="email" v-model="data.email" class="hl-input" :class="getErrorForField('email')" placeholder="Email address (secure)" />
            <div style="position:absolute; top:8px; right:5px;">
            <svg style="opacity:0.5"
                    xmlns:dc="http://purl.org/dc/elements/1.1/"
                    xmlns:cc="http://creativecommons.org/ns#"
                    xmlns:rdf="http://www.w3.org/1999/02/22-rdf-syntax-ns#"
                    xmlns:svg="http://www.w3.org/2000/svg"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 -256 1792 1792"
                    id="svg2989"
                    version="1.1"
                    width="20px"
                    height="20px">
                <metadata
                        id="metadata2999">
                    <rdf:RDF>
                        <cc:Work
                                rdf:about="">
                            <dc:format>image/svg+xml</dc:format>
                            <dc:type
                                    rdf:resource="http://purl.org/dc/dcmitype/StillImage" />
                        </cc:Work>
                    </rdf:RDF>
                </metadata>
                <defs></defs>
                <g
                        transform="matrix(1,0,0,-1,318.91525,1346.1695)"
                        id="g2991">
                    <path
                            d="m 704,512 q 0,53 -37.5,90.5 Q 629,640 576,640 523,640 485.5,602.5 448,565 448,512 q 0,-37 19,-67 19,-30 51,-47 L 449,169 q -5,-15 5,-28 10,-13 26,-13 h 192 q 16,0 26,13 10,13 5,28 l -69,229 q 32,17 51,47 19,30 19,67 z M 320,768 h 512 v 192 q 0,106 -75,181 -75,75 -181,75 -106,0 -181,-75 -75,-75 -75,-181 V 768 z m 832,-96 V 96 Q 1152,56 1124,28 1096,0 1056,0 H 96 Q 56,0 28,28 0,56 0,96 v 576 q 0,40 28,68 28,28 68,28 h 32 v 192 q 0,184 132,316 132,132 316,132 184,0 316,-132 132,-132 132,-316 V 768 h 32 q 40,0 68,-28 28,-28 28,-68 z"
                            id="path2993"
                            style="fill:gray" />
                </g>
            </svg>
            </div>
            </div>
        </div>
        <div class="form-group">
            <label for="">Phone <span class="error" v-if="hasError('phone')">You must enter a phone number</span></label>
            <input type="text" class="hl-input" v-model="data.phone" :class="getErrorForField('phone')" placeholder="phone number (optional)" />
        </div>

        <div class="form-group">
            <label for="">What can we help you with?</label>
            <select name="" v-model="data.contactReason" class="hl-input">
                <option value="Meet with Agent">Meet with Agent</option>
                <option value="Property Valuation">Property Valuation</option>
                <option value="Sell my Property">Sell my Property</option>
                <option value="Other"> Something Else</option>
            </select>
        </div>
            <a v-if="!enterMessage && data.contactReason != 'Other' " class="hl-blue" @click="enterMessage = true;" href="#">Click here to enter an optional message</a>

         <div class="form-group" v-if="data.contactReason == 'Other' || enterMessage">
             <label for="">Optional</label>
                <textarea v-model="data.notes" placeholder="Message, question, issue, aloha..."></textarea>
         </div>
            <p>We will never share your information or spam you because that would be lame.</p>
            <a v-if="!sent" @click="send" href="#" class="hl-button" :class="{disabled:submittedAndHasErrors}">Submit</a>
            <div v-if="sent" class="modal-mask modal-success" style=""><div class="modal-wrapper"><div class="modal-container"><a class="modal-close"><span class="u-screen-reader">Close<span></span></span></a> <h3 headline="Contact. Cool. We’ll get right back to you." class="modal-headline">Contact. Cool. We’ll get right back to you.</h3> <p body="Hawai‘i Life is human (no robots) and people do need to sleep, so it may take a bit longer to respond to after-hours inquiries. Mahalo." class="modal-body">Hawai‘i Life is human (no robots) and people do need to sleep, so it may take a bit longer to respond to after-hours inquiries. Mahalo.</p></div></div></div>
        </div>
    </div>
</template>

<script>
    import axios from 'axios';
        const apiBase = window.location && window.location.href.includes('localhost') ? 'http://localhost:3000/api/lead/create' : 'https://platform.hawaiilife.com/api/lead/create';
    export default {
        props:['title','defaultoption'],
        name: "LeadForm",
        data(){
                        return {
                sent:false,
                enterMessage:false,
                submitted:false,
                sending:false,
                data:{
                    name:null,
                    email:null,
                    phone:null,
                    contactReason: this.defaultoption ? this.defaultoption : 'Meet with Agent',
                    notes:'',
                }


            }
        },
        methods:{
            async send(){
                this.sending = false;
                const {data} = await axios.post(apiBase,this.data);
                this.sending = false;
                this.sent = true;
            },
            hasError(field){
                return this.getErrorForField(field).error;
            },
            getErrorForField(field){
                const val = this.data[field];
                if(!val && this.submitted){
                    return {error:true};
                }

                return {};

            }
        },
        computed:{
            hasErrors:function(){
                const hasError = this.hasError('email') || this.hasError('name') || this.hasError('contactReason');
                return hasError;
            },
            submittedAndHasErrors(){
                const hasError = this.hasErrors;
                if(hasError && this.submitted){
                    return true;
                }
                return false;
            }
        }
    }
</script>

<style lang="scss" scoped>
    @import '../assets/styles';
    .contact-form{
        width:100%;
        max-width:600px;
        box-sizing:border-box;
        padding:10px;
        position:relative;
        @media screen and (max-width:400px){
            max-width:100vw;
        }
    }
    .error-panel{
        background-color: #F15C22;
        color:white;
        padding:10px;
        font-weight:700;
    }

    .hl-input{
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        box-sizing: border-box;
        border: 1px solid #bdbdbd;
        font-size: 15px;
        transition: background .3s ease;
        display: block;
        width: 100%;
        padding: 1px 35px 1px 12px;
        line-height: 36px;
        height: 38px;
        &:active,&:hover{
            background-color:#f8f8f8;
        }
        &.error{
            border:1px red solid;
        }
    }

    textarea{
        resize: vertical;
        vertical-align: top;
        height: 150px;
        min-height: 150px;
        max-height: 250px;
        line-height: 18px;
        padding: 9px 12px;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        box-sizing: border-box;
        border: 1px solid #bdbdbd;
        font-size: 15px;
        transition: background .3s ease;
        display: block;
        width: 100%;
    }

    div.form-group{
        font-family: "Whitney A", "Whitney B", sans-serif;
        display:flex;
        flex-direction:column;
        justify-content: center;
        label{
            font-weight:700;
            width:auto;
            padding-bottom:10px;
            position:relative;
            span.error{
                position:absolute;
                display:inline-block;
                background-color: #F15C22;
                color:white;
                margin-left:20px;
                padding:5px;
                top:-5px;

            }
        }
        padding-bottom:30px;
    }

    .modal-success .modal-container {
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
        padding: 35px;
        background-color: #12b5ea;
        color: #fff;
        box-shadow: 0 3px 9px rgba(0,0,0,0.5);
        transition: all .3s ease;
        font-family: "Whitney A", "Whitney B", Helvetica, Arial, sans-serif;
    }

    .overlay-cover{
        position:absolute;
        top:0;
        left:0;
        z-index:1000;
        background-color: rgba(255,255,255,0.5);
        height:100%;
        min-height:600px;
        width:100%;
        display:flex;
        justify-content: center;
        /* align-self: center;    <---- REMOVE */
        align-items: center;   /* <---- NEW    */
    }

    div.form-group{
        position:relative;
        .input-wrapper-with-icon{
            svg{
                align-self: flex-end;
            }

        }
    }

</style>
