<template>
<div v-if="showModal" class="sign-up-for-newsletter">
<div v-if="!showSuccess" class="inner-div">
  <h2>Get <span class="bolder"><span class="hl-blue">Hawaii</span><span class="green">Life</span>Style</span> in Your Inbox</h2>
  <p>Receive first to know info on Hawaii real estate and investing.</p>
  <div class="center-content"><input type="text" v-model="email" :class="{'error':error}" required placeholder=">> Enter Email" class="hl-input" /><a @click="signup" class="hl-button inline">Sign Up</a></div>
  <a class="dismiss" @click="dismiss">No Thanks</a>
</div>
  <div v-if="showSuccess">
    <h2>You're subscribed to <span class="bolder"><span class="hl-blue">Hawaii</span><span class="green">Life</span>Style.</span></h2>
    <p>Hawaii Life delivers publication emails weekly. Aloha.</p>
    <a class="dismiss" @click="dismiss">Got it!</a>
  </div>
</div>
</template>

<script>
import axios from 'axios';
import Cookie from 'js-cookie';
import store from "../store";
export default {
  name: "HawaiiLifeStyleSignup",
  data(){
    return {
      show:false,
      delayToShow:3000,
      timeoutFired:false,
      showSuccess:false,
      email:'',
      href:'',
      error:false,
    }
  },
  mounted(){
    this.href = window.location.pathname;
    window.HL_SHOW_SIGNUP_NEWSLETTER = () => {
      this.show = true;
      this.timeoutFired = true;
    }

    if(!Cookie.get('dismissed_hawaii_lifestyle_signup')){
      this.show = true;
      this.startTimer();
    }
  },
  computed:{
    showModal(){
        return this.timeoutFired && this.show; //&& !Cookie.get('dismissed_hawaii_lifestyle_signup');
    }
  },
  methods:{
    startTimer() {
      if (this.resolveTimeoutFromHref(this.href)) {
        window.setTimeout(() => {
          this.timeoutFired = true;
                  }, this.resolveTimeoutFromHref(this.href));
      }
    }
    ,
    resolveTimeoutFromHref(href){
      if(Cookie.get('client_name')){
        return false;
      }
      if(href.includes('/blog')){
        return 30000;
      }
      if(href === ('/') || href === ""){
        return 60000;
      }
      return false;
    },
    dismiss(){
      Cookie.set('dismissed_hawaii_lifestyle_signup', '1',7);
      this.show = false;
      this.timeoutFired = false;
    },
    async signup(){
      const url = 'https://platform.hawaiilife.com/subscribe';
      if(this.email){
        this.error = false;
        await axios.post(url,{email:this.email,list_id:'fab580be3f6c8ca85ffb9d7b5b927a13'});
        this.showSuccess = true;
        window.setTimeout(() => {
          this.show = false;
          Cookie.set('dismissed_hawaii_lifestyle_signup','1',365);
        },5000);
      }else{
        this.error = 'Please Enter an Email';
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.sign-up-for-newsletter{
  text-align: center;
  position:fixed;
  bottom:0px;
  width:100vw;
  left:0px;
  min-height:20vh;
  background-color: rgba(255,255,255,0.95);
  padding:0px;
  padding-top:0px;
  display:flex;
  align-content: center;
  justify-content: center;
  z-index:10000;
  border-top:1px #f8f8f8 solid;
  @media(max-width:800px){
    min-height:200px;
    padding:0px;

  }
  h2{
    font-family: "Whitney A", "Whitney B", sans-serif;
    font-size:40px;
    font-weight:400;
    margin-bottom:20px;
    @media(max-width:800px){
      font-size:24px;

    }
  }
  span.bolder{
    font-weight:500;
  }
  p{
    margin-bottom:20px;
    font-size:16px;
  }

  .center-content{
    margin-bottom:10px;
    display:flex;
    flex-direction: row;
    align-items: center;
    margin-bottom:20px;
    .hl-button{
      &:hover{
        text-decoration: none;
      }
      width:auto;
      min-width:150px;
      padding-top:11px;
      padding-bottom:11px;
      margin-top:0px;
      @media(max-width:800px){
        min-width:100px;
        font-weight:700;
        font-size:15px;

      }
    };
  }
  a{
    color:black;
  }
  a.dismiss{
    text-decoration: underline;
    &:hover{
      cursor:pointer;
    }
    margin-top:40px;
  }

}

</style>
