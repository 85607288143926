<template>
    <modal v-if="showGenericContactModal" :layout="genericContactModalSize" :close-modal="closeModal">
      <working v-if="showWorking">
        <h2>Just A Second</h2>
      </working>
      <div class="standalone-modal-content" :class="genericContactModalTheme">
      <div v-if="!submitted">
          <div>
            <h2>{{genericContactModalHeader}}</h2>

            <div class="image-and-text" v-if="genericContactModalImage">
              <div>
                <img v-if="genericContactModalImage" :src="genericContactModalImage" alt="" />
              </div>
              <div>
                <p>{{genericContactModalText}}</p>
              </div>
            </div>
            <div>
              Valid Form {{this.formError() ? 'No' : 'Yes'}}
              Errors
              {{JSON.stringify(this.formFieldErrors)}}
            </div>
             <div>
             <div v-if="!genericContactModalImage">
               <p>{{genericContactModalText}}</p>
             </div>
            <h5>Email</h5>
            <input
              type="text"
              class="hl-input" v-model="email" :class="{error:changed && !this.emailValid()}" @keyup="changed = true;"
              placeholder="enter email address"
            />
            </div>
            <div v-if="showGenericContactModalPhoneNumber">
              <h5>Phone Number</h5>
              <input
                  type="text"
                  class="hl-input" v-model="phone" @keyup="changed = true;" :class="{error:changed && !this.phoneValid()}"
                  placeholder="Phone number"
              />
            </div>
            <h5>Message</h5>
            <textarea class="hl-input" id="" cols="30" rows="20" @keyup="changed = true;" :class="{error:changed && (!message || !message.trim().length)}"  placeholder="How can we help?" v-model="message">

            </textarea>
          </div>
          <a class="contact-button" @click="submit" :class="{ disabled : changed && !formValid()}">{{
            genericContactModalButtonText
          }}</a>
        </div>
      </div>
    </modal>
</template>

<script>
import {mapGetters} from 'vuex';
import Modal from "@/components/Modal";
import Working from "@/components/Working";
export default {
  name: "Contact-Modal",
  components:{
    Modal,
    Working,
  },
  data() {
    return {
      formFieldErrors:{},
      showContact: true,
      message:'',
      email:null,
      phone:null,
      showWorking:false,
      submitted:false,
      changed:false,
    };
  },
  methods:{
    closeModal(){
      this.message = '';
      this.email = '';
      this.$store.commit('setGenericContactModalHidden');
    },
    formValid(){
      return this.emailValid() && this.phoneValid() && this.messageValid();
    },
    messageValid(){
      return this.message && this.message.trim().length
    },
    submit(){
      this.changed = true;
      if(this.formError()){
        alert('we have an error');
        return;
      }
      alert('we would send this');

    },
    emailValid(){
      if(!this.email || !this.email.length){
        this.formFieldErrors['email'] = true;
        return false;
      }
      const valid = this.emailRegex.test(this.email);
      if(valid){
        this.formFieldErrors['email'] = false;
        return true;
      }
      this.formFieldErrors['email'] = true;
    },
    phoneValid(){
      if(!this.phone || !this.phone.length){
        return false;
      }
      const valid = this.phoneRegex.test(this.phone);
      if(valid){
        this.formFieldErrors['phone'] = false;
        return true;
      }
      this.formFieldErrors['phone'] = true;
      return false;
    },
    formError(){
      const emailValid = this.emailValid();
      const phoneValid = this.phoneValid();
      return phoneValid && emailValid ? false : true;
    },
  },
  computed:{
    ...mapGetters([
        'showGenericContactModal',
        'genericContactModalHeader',
        'genericContactModalText',
        'genericContactModalButtonText',
        'showGenericContactModalPhoneNumber',
        'genericContactModalSize',
        'genericContactModalImage',
        'genericContactModalTheme','emailRegex','phoneRegex'])
  }
};
</script>

<style lang="scss">
    .standalone-modal-content {
      box-sizing: border-box;
      padding:20px;
      > div{
        width:100%;
        margin:0 auto;
      }
      h5{
        font-family: "Whitney A","Whitney B",sans-serif;
        margin-bottom:5px;
      }
      input[type="text"],textarea{
        padding-top:10px;
        padding-bottom:10px;
        font-size:17px;
        &::placeholder{
          color:#9e9e9e;
        }
        &.error{
          outline:1px rgba(255,187,151,1) solid;
        }
      }
      input[type="text"]{
       padding-top:20px;
       padding-bottom:20px;
      }
      min-height: 200px;
      height: auto;
      max-height: 75vh;
      overflow-y: scroll;
      width: 100%;
      min-height:50vh;
      background-color: rgba(255, 255, 255, 1);
      box-sizing: border-box;
      display: flex;
      justify-content: center;
      justify-items: center;
      .contact-button{
        padding:15px 10px;
        box-sizing: border-box;
        display:block;
        background-color:black;
        color:white;
        text-align: center;
        width:100%;
        margin-top:20px;
        &.disabled{
          opacity:0.7;
          &:hover{
            cursor: not-allowed;
          }
        }
        &:hover{
          background-color: #333333;
          text-decoration: none;
          font-weight:bold;
        }
      }
      textarea{
        min-height:150px;
      }
      /**
      Theme stuff here
       */
      &.hawaii-life{
        .contact-button{
          background-color: #12b5ea;
          font-family: "Whitney A","Whitney B",sans-serif;
          font-weight:700;
          font-size:22px;
        }
      }

      .image-and-text{
        display:flex;
        flex-direction:row;
        > div{
          flex: 2 1 auto;
        }
        img{
          max-width:200px;
        }
      }



  }



</style>
