import Vue from 'vue'
import store from './store'
import Listings from './components/Listings.vue';
import RecentActivityMap from "./components/RecentActivityMap";
import RecentActivityList from './components/RecentActivityList';
import ContactLeadTeam from './components/ContactLeadTeam';
import BlogList from './components/BlogList';
import RelatedListing from './components/RelatedListing';
import FeaturedBlogPosts from "./components/FeaturedBlogPosts";
import LeadForm from './components/LeadForm';
import CEComponent from "./components/CEComponent";
import Instagram from "./components/Instagram";
import FeaturedCollections from "./components/FeaturedCollections";
import ContactModal from './components/Contact-Modal';
import FeaturedBlogPostsStacked from './components/FeaturedBlogPostsStacked'
import AutocompleteSearch from "./components/AutocompleteSearch";
import ScheduleCallBackModal from "./components/ScheduleCallBackModal";
import AreaAdditionalContent from "@/components/AreaAdditionalContent";
import MatterportEmbed from "@/components/MatterportEmbed";
import Welcome from "@/Welcome";
import Forbes from "@/components/Forbes";
import RequestShowing from "@/components/RequestShowing";
import RequestShowingButton from "@/components/RequestShowingButton";
import MLSPoweredListings from "@/components/MLSPoweredListings";
import MapListings from "@/components/MapListings";
import AfterRegistration from "@/components/AfterRegistration";
import FullScreenBackground from "@/components/FullScreenBackground";
import HawaiiLifeStyleSignup from "@/components/HawaiiLifeStyleSignup";
import HawaiiLifeStyleInline from "./components/HawaiiLifeStyleInline";
import AgentSearch from "./components/AgentSearch";
import QuerySlideshow from "@/components/QuerySlideshow";
import SearchMLSListings from "@/components/SearchMLSListings.vue";
import WelcomeV2 from "@/WelcomeV2.vue";
import CTAModal from "./components/CTAModal.vue";
import CTABanner  from "./components/CTABanner.vue";
import Cookie from "js-cookie";
Vue.config.productionTip = false;

const getAttributesForElement = (element) => {
  let dataAtts = {};
  for(var attribute of element.attributes){
    dataAtts[attribute.name.replace('data-','')] = attribute.value;
  }
  return dataAtts;
}

let inited = false;
const init = (skipCheckInit = false) => {
  if (inited && !skipCheckInit) return;
  const listings = document.querySelectorAll('.listings');
  if (listings && listings.length) {
    for (var listing of listings) {
      var target = listing.querySelector('.target');
      let dataAtts = getAttributesForElement(listing);
      if (target) {
        new Vue({
          store,
          render: h => h(Listings, {
            props: dataAtts,
          })
        }).$mount(target);
      }
    }
  }
  const fsv = document.querySelectorAll('.hl-video-bg')
  if (fsv && fsv.length) {
    for (var f of fsv) {
      var target = f;
      let dataAtts = getAttributesForElement(target);
      console.log('f',f);
      const content = f.children;
      console.log('content',content[0]);
      const props = {...dataAtts,content:content[0].outerHTML}
      console.log('props',props);
      if (target) {
        new Vue({
          store,
          render: h => h(FullScreenBackground, {
            props,
          })
        }).$mount(target);
      }
    }
  }

  const welcomeTarget = document.querySelector('#welcome');
  if (welcomeTarget) {
    new Vue({
      store,
      render: h => h(Welcome, {props: getAttributesForElement(welcomeTarget)})
    }).$mount(welcomeTarget);

  }

  const welcomeTargetV2 = document.querySelector('#welcome2');
  if (welcomeTargetV2) {
    new Vue({
      store,
      render: h => h(WelcomeV2, {props: getAttributesForElement(welcomeTargetV2)})
    }).$mount(welcomeTargetV2);

  }

  const ceTarget = document.querySelector('#ce-component');
  if (ceTarget) {
    new Vue({
      store,
      render: h => h(CEComponent, {props: getAttributesForElement(ceTarget)})
    }).$mount(ceTarget);

  }

  const rsTarget = document.querySelector('#request-showing');
  if (rsTarget) {
    new Vue({
      store,
      render: h => h(RequestShowing, {props: getAttributesForElement(rsTarget)})
    }).$mount(rsTarget);

  }

  setTimeout(() => {
    const recentActivityMapTarget = document.querySelector('.recent-activity-map');
    if (recentActivityMapTarget) {

      new Vue({
        store,
        render: h => h(RecentActivityMap, {})
      }).$mount(recentActivityMapTarget);
    }

    const acSearch = document.querySelector('.autocomplete-search-standalone');
    if (acSearch) {
      var atts = getAttributesForElement(acSearch);
      new Vue({
        store,
        render: h => h(AutocompleteSearch, {props: atts})
      }).$mount(acSearch);
    }

    const ceScheduleCall = document.querySelector('.ce-schedule-call-modal');
    if (ceScheduleCall) {
      var atts = getAttributesForElement(ceScheduleCall);
      new Vue({
        store,
        render: h => h(ScheduleCallBackModal, {props: atts})
      }).$mount(ceScheduleCall);
    }


    const recentActivityListTarget = document.querySelector('.recent-activity-list');
    if (recentActivityListTarget) {
      var atts = getAttributesForElement(recentActivityListTarget);
      new Vue({
        store,
        render: h => h(RecentActivityList, {props: atts})
      }).$mount(recentActivityListTarget);
    }

    const clt = document.querySelector('.contact-lead-team');
    if (clt) {
      let dataAtts = getAttributesForElement(clt);
      new Vue({
        store,
        render: h => h(ContactLeadTeam, {props: dataAtts})
      }).$mount(clt);
    }

    setTimeout(() => {
      const blog_posts = document.querySelectorAll('.blog-posts');
      if (blog_posts && blog_posts.length) {
        for (var blog_post of blog_posts) {
          var target = blog_post;
          let dataAtts = getAttributesForElement(target);
          if (target) {
            new Vue({
              store,
              render: h => h(BlogList, {
                props: dataAtts,
              })
            }).$mount(target);
          }
        }
      }
    }, 50);
    const contact_modals = document.querySelectorAll('.contact-modal');
    if (contact_modals && contact_modals.length) {
      for (var contact_modal of contact_modals) {
        var target = contact_modal;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(ContactModal, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }
    setTimeout(() => {
      const featured_blog_posts = document.querySelectorAll('.featured-blog-posts');
      if (featured_blog_posts && featured_blog_posts.length) {
        for (var blog_post of featured_blog_posts) {
          var target = blog_post;
          let dataAtts = getAttributesForElement(target);
          if (target) {
            new Vue({
              store,
              render: h => h(FeaturedBlogPosts, {
                props: dataAtts,
              })
            }).$mount(target);
          }
        }
      }
    }, 150);

    const rsc = document.querySelectorAll('.request-showing-component');
    if (rsc && rsc.length) {
      for (var r of rsc) {
        var target = r;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(RequestShowingButton, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }


    const featured_blog_posts_stacked = document.querySelectorAll('.featured-blog-posts-stacked');
    if (featured_blog_posts_stacked && featured_blog_posts_stacked.length) {
      for (var blog_post of featured_blog_posts_stacked) {
        var target = blog_post;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(FeaturedBlogPostsStacked, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }

    const featured_collections = document.querySelectorAll('.featured-collection');
    if (featured_collections && featured_collections.length) {
      for (var collection of featured_collections) {
        var target = collection;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(FeaturedCollections, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }

    const leadForms = document.querySelectorAll('.lead-form');
    for (var lf of leadForms) {
      var target = lf;
      let dataAtts = getAttributesForElement(target);
      if (target) {
        new Vue({
          store,
          render: h => h(LeadForm, {
            props: dataAtts,
          })
        }).$mount(target);
      }
    }

    const relatedContentSelectors = ['article.blog-post a'];
    for (var selector of relatedContentSelectors) {
      const results = document.querySelectorAll(selector);
      for (var result of results) {
        const url = result.href;
        const listingMatch = url.match(/\/listings\/([aZ-zZ0-9\-]+)$/);
        const target = document.querySelector('.related-content.target');
        if (listingMatch) {
          //we only have one related target match.. so we just mount to that
          if (target) {
            new Vue({
              store,
              render: h => h(RelatedListing, {
                props: {type: 'listing', url: listingMatch[1]},
              })
            }).$mount(target);
          }
        }
      }
    }
    const ig_feeds = document.querySelectorAll('.hl-instagram-feed');
    if (ig_feeds && ig_feeds.length) {
      for (var ig of ig_feeds) {
        var target = ig;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(Instagram, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }


    const map_listings = document.querySelectorAll('.area-content-data');
    if (map_listings && map_listings.length) {
      for (var ml of map_listings) {
        var target = ml;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(AreaAdditionalContent, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }

    const inline_signup = document.querySelectorAll('.hawaiilife-signup-inline');
    if (inline_signup && inline_signup.length) {
      for (var ml of inline_signup) {
        var target = ml;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(HawaiiLifeStyleInline, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }

    const matterport_embeds = document.querySelectorAll('.matterport-embed');
    if (matterport_embeds && matterport_embeds.length) {
      for (var mp_embed of matterport_embeds) {
        var target = mp_embed;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(MatterportEmbed, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }
    const forbes_promos = document.querySelectorAll('.forbes-promo');
    if (forbes_promos && forbes_promos.length) {
      for (var promo of forbes_promos) {
        var target = promo;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(Forbes, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }

    //attach-open-contact-modal
    const ars = document.querySelectorAll('.after-registration');
    if (ars && ars.length) {
      for (var ar of ars) {
        var target = ar;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(AfterRegistration, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }

    const ctaModal = document.querySelectorAll('.cta-modal-trigger');
    let addedDiv = null;
    let currentCTAInstance = null;
    const addCTADiv = () => {
      console.log('---------------',addedDiv);
      if(!document.getElementById('added-div' )){
        var div = document.createElement('div');
        div.id = 'added-div';
        document.body.appendChild(div);
        addedDiv = div;
      }else{
        console.log("we are skipping adding the div");
      }
    }
    const removeCTADiv = () => {
      if (document.getElementById('added-div')) {
        document.getElementById('added-div').remove();
      }
    }
    if (ctaModal && ctaModal.length) {
      addCTADiv();
      const i = new Vue({
        store,
        render: h => h(CTAModal, {
        })
      }).$mount(document.getElementById('added-div'));
      for (var ar of ctaModal) {
        console.log(ar);
        ar.addEventListener('click', function(e){
          let dataAtts = getAttributesForElement(this);
          console.log(dataAtts);
          store.commit('setActiveCTATemplateName', dataAtts.template);
          store.commit('updateShowCTAModal',true);
        });
      }
    }



    const mlsListings = document.querySelectorAll('.hl-mls-listings');
    if (mlsListings && mlsListings.length) {
      for (var l of mlsListings) {
        var target = l;
        let dataAtts = getAttributesForElement(target);
        if (target) {
         const i =  new Vue({
            store,
            render: h => h(MLSPoweredListings, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }

    const searchMLSListings = document.querySelectorAll('.hl-query-search-mls-listings');
    if (searchMLSListings && searchMLSListings.length) {
      for (var l of searchMLSListings) {
        var target = l;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(SearchMLSListings, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }


    const mapListings = document.querySelectorAll('.hl-map-listings');
    if (mapListings && mapListings.length) {
      for (var l of mapListings) {
        var target = l;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(MapListings, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }

    const querySlideshows = document.querySelectorAll('.hl-query-slideshow');
    if (querySlideshows && querySlideshows.length) {
      for (var l of querySlideshows) {
        var target = l;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(QuerySlideshow, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }
    const findAgent = document.querySelectorAll('.agent-search__column form');
    var matchCompany = window.location.pathname === '/company' || window.location.pathname === '/company/'  ;
    if (findAgent && findAgent.length && matchCompany) {
      for (var l of findAgent) {
        var target = l;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(AgentSearch, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }
    
    let signup = document.querySelectorAll('#hawaii-life-style-signup');
    if(!signup || !signup.length){
      let divg = document.createElement('div');
      divg.setAttribute("id", "hawaii-life-style-signup");
      document.body.appendChild(divg);
      signup = document.querySelectorAll('#hawaii-life-style-signup');
    }

    let modalDivInjected = document.querySelectorAll('#hawaii-life-cta-modal');
    if(!modalDivInjected || !modalDivInjected.length){
      let divg = document.createElement('div');
      divg.setAttribute("id", "hawaii-life-cta-modal");
      document.body.appendChild(divg);
      console.log('we are appending the div');
      signup = document.querySelectorAll('#hawaii-life-cta-modal');
      console.log('signup',signup);
    }

    let mauiFireDiv = document.querySelectorAll('#maui-fire-div');

    const mountBanner = function(){
      let mount = false;
      if(Cookie.get('dismissed_cta_banner')) return false;
      const location = window.location.origin;
      const path = window.location.pathname;
      const domains = [
        'https://www.hawaiilife.com',
        'https://hawaiilife.com',
        'https://huli.hawaiilife.com',
        'https://aws-stage.hawaiilife.com',
        'http://localhost:8080',
        'https://about.hawaiilife.com',
      ]


      if(domains.indexOf(location) !== -1){
        if(path === '/'){
        mount = true;
        }
        //path starts with /blog
        if(path.indexOf('/blog') === 0){
          mount = true;
        }
        if(path.indexOf('/company') === 0){
          mount = true;
        }
        if(path.indexOf('/blog/maui-strong') === 0){
          mount = false;
        }
      }
      return mount;
    }




    if((!mauiFireDiv || !mauiFireDiv.length) && mountBanner()){
      let divg = document.createElement('div');
      divg.setAttribute("id", "maui-fire-div");
      document.body.appendChild(divg);
      document.body.classList.add('cta-banner-added');
      if (divg) {
        new Vue({
          store,
          render: h => h(CTABanner, {
            props: {},
          })
        }).$mount(divg);
      }
    }







    const mountSignup = function(){
      const location = window.location.origin;
      const path = window.location.path;
      console.log('path',path);
      console.log('location',location);
      const domains = [
          'https://www.hawaiilife.com',
          'https://hawaiilife.com',
          'https://huli.hawaiilife.com',
          'https://aws-stage.hawaiilife.com',
          'http://localhost:8080',
          'http://localhost:8081',
          'http://localhost:3000',
          'http://localhost:5000',
      ]


      if(domains.indexOf(location) !== -1){
        return true;
      }
    }
    const mount = mountSignup();


    if (signup && signup.length && mount) {
      for (var l of signup) {
        var target = l;
        let dataAtts = getAttributesForElement(target);
        if (target) {
          new Vue({
            store,
            render: h => h(HawaiiLifeStyleSignup, {
              props: dataAtts,
            })
          }).$mount(target);
        }
      }
    }

    const triggerForSignup = document.getElementById('trigger-hawaii-life-style-signup-modal');


    if(triggerForSignup) triggerForSignup.addEventListener('click',()=> {
      if(window.HL_SHOW_SIGNUP_NEWSLETTER){
        window.HL_SHOW_SIGNUP_NEWSLETTER();
      }
    });
  }, 200);
}


const initAfterLoad = () => {
  if(window.addEventListener) {
    window.addEventListener('load',init,false); //W3C
  } else {
    if(window.attachEvent) window.attachEvent('onload',init); //IE
  }

  setTimeout(() => {
    init();
  },100);
  var ocf = document.querySelectorAll('.open-call-form');
  for (const button of ocf) {
    button.addEventListener('click', function(event) {
      event.preventDefault();
      window.HL_ON_SCHEDULE_CALL_OPEN();
    })
  }
  var ocf = document.querySelectorAll('.show-call-modal');
  for (const button of ocf) {
    button.addEventListener('click', function(event) {
      event.preventDefault();
      window.HL_ON_SHOW_CALL_INLINE();
    })
  }
}



if(window) window.HL_WEB_COMPONENT_INIT = init;
var loaded = false;
try {
  if(window.requestIdleCallback){
    window.requestIdleCallback(initAfterLoad);
    loaded = true;
  }else if(window.requestAnimationFrame) {
    window.requestAnimationFrame(initAfterLoad);
    loaded = true;
  }else{
    initAfterLoad();
    loaded = true;
  }
  if(!loaded){
    loaded = true;
    initAfterLoad();
  }
  inited = true;
  window.HL_WEB_COMPONENTS_INITIALIZED = true;
  setTimeout(() => {
    initAfterLoad();
  },1);
  window.HL_SET_AREA_CONTENT_DATA = (data) => {
    store.commit('setAreaContentData',data);
  }
  window.HL_SET_LISTING_RESULTS_DATA = (data) => {
    store.commit('setListingResultsData',data);
  }
  window.HL_SET_RELATED_BLOGS = (data) => {
    store.commit('setRelatedBlogs',data);
  }
  window.HL_SET_RELATED_LISTINGS = (data) => {
    store.commit('setRelatedListings',data);
  }
  window.HL_SET_CURRENT_ISLAND = (data) => {
    store.commit('setCurrentIsland',data);
  }
  window.HL_SET_CURRENT_DISTRICT = (data) => {
    store.commit('setCurrentDistrict',data);
  }
  window.HL_SET_CURRENT_NEIGHBORHOOD = (data) => {
    store.commit('setCurrentNeighborhood',data);
  }
  window.HL_SET_CURRENT_COMMUNITY = (data) => {
    store.commit('setCurrentCommunity',data);
  }

  window.HL_SHOW_GENERIC_CONTACT_MODAL = (data = null) => {
    store.commit('fillGenericContactModalFromData',data);
    store.commit('setShowGenericContactModal',true);
    //store.commit('setShowGenericContactModal',true);
  }

  window.SET_HL_USERS_ASSIGNED_AGENT = (data) => {
    store.commit('setUserAdvisorForCEWidget',data);
  }
  window.sendAnalyticsEvent = (type = 'view',category,action,label,value,fields  = {}) => {
    var t = window.gtag;
    if(t) {
      t('event', 'click', {
        hitType: type,
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        eventValue: value,
        ...fields,
      });
    }
  }




  window.hlGoogleMapInited = () => {
    store.commit('mapsLoaded',1);
  }

  if(window.HL_FEATURED_ADVISORS_FOR_LISTING){
    store.commit('setFeaturedAdvisorsForListing',window.HL_FEATURED_ADVISORS_FOR_LISTING);
  }
   setTimeout(() => {
     window.sendAnalyticsEvent('webcomponent','view','viewed',1);
   },500);

}catch(e){
  if(window.Sentry) Sentry.captureException(e);
  //dont do anything..
}

window.HL_TRIGGER_EMBEDDED_CHAT = function(){
  try {
    window.embedded_svc.inviteAPI.inviteButton.acceptInvite();
  }catch(e){
    console.log('error',e);
  }
}
